/**
 * Main
 */

html {
    background-color: $background-color;
    color: $text-color;
    font-family: $font-sans;
    height: 100%;
}

body {
    min-height: 100%;
}
