/**
 * Style
 */

@charset "UTF-8";

// 1. Settings
@import '1-settings/base';
@import '1-settings/colors';
@import '1-settings/fonts';

@import '1-settings/elements/heading';
@import '1-settings/elements/horizontal-rule';
@import '1-settings/elements/image';
@import '1-settings/elements/link';
@import '1-settings/elements/list';
@import '1-settings/elements/paragraph';
@import '1-settings/elements/selection';

@import '1-settings/objects/alert';
@import '1-settings/objects/button';
@import '1-settings/objects/flex-embed';
@import '1-settings/objects/form';
@import '1-settings/objects/grid';
@import '1-settings/objects/nav';
@import '1-settings/objects/structure';
@import '1-settings/objects/table';

@import '1-settings/components/fields';
@import '1-settings/components/cookie';

// 2. Tools
@import '2-tools/functions/throw';
@import '2-tools/functions/checks';
@import '2-tools/functions/truncate-list';
@import '2-tools/functions/maps';
@import '2-tools/functions/math';
@import '2-tools/functions/units';
@import '2-tools/functions/string';
@import '2-tools/functions/svg';

@import '2-tools/mixins/background-svg';
@import '2-tools/mixins/font-responsive';
@import '2-tools/mixins/font-size';
@import '2-tools/mixins/font-smoothing';
@import '2-tools/mixins/images';
@import '2-tools/mixins/maps';
@import '2-tools/mixins/queries';
@import '2-tools/mixins/last-children';
@import '2-tools/mixins/grid';

// 3. Generic
@import '3-generic/reset';

// 4. Elements
@import '4-elements/horizontal-rule';
@import '4-elements/image';
@import '4-elements/link';
@import '4-elements/list';
@import '4-elements/selection';
@import '4-elements/heading';
@import '4-elements/paragraph';
@import '4-elements/main';

// 5. Objects
@import '5-objects/alert';
@import '5-objects/button';
@import '5-objects/flex-embed';
@import '5-objects/form';
@import '5-objects/grid';
@import '5-objects/icon';
@import '5-objects/nav';
@import '5-objects/structure';
@import '5-objects/table';

// 6. Components
@import '6-components/fields/_field';
@import '6-components/fields/_field_check';
@import '6-components/fields/_field_file';
@import '6-components/fields/_field_input';
@import '6-components/fields/_field_select';

@import '6-components/alert';
@import '6-components/button';
@import '6-components/cookie';
@import '6-components/footer';
@import '6-components/general';
@import '6-components/header';
@import '6-components/logo';
@import '6-components/nav';
@import '6-components/outdated';
@import '6-components/print';

// 7. Trumps
@import '7-trumps/after';
@import '7-trumps/align';
@import '7-trumps/before';
@import '7-trumps/display';
@import '7-trumps/flex';
@import '7-trumps/layout';
@import '7-trumps/position';
@import '7-trumps/link';
@import '7-trumps/size';
@import '7-trumps/spacing';
@import '7-trumps/text';
@import '7-trumps/lastChildren';
