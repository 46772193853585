/**
 * Components: Cookie
 */

$cookie-background-color:                             $black !default;
$cookie-text-color:                                   $white !default;
$cookie-button-background-color:                      $white !default;
$cookie-button-text-color:                            $black !default;
$cookie-link-color:                                   $white !default;

// Border
$cookie-border-radius:                                5 !default;
