/**
 * Elements: Link
 */

// Text decoration
$link-text-decoration:                    underline !default;
$link-text-decoration--hover:             none !default;

// Colors
$link-color:                              $indigo !default;
$link-color--hover:                       $indigo--600 !default;
