/**
 * Nav
 */

// Main

.Nav--main {

    .Nav-listToggle {}
    .Nav-list {}
    .Nav-item {}
    .Nav-link {}
}


// Languages

.Nav--lang {

    .Nav-listToggle {}
    .Nav-list {}
    .Nav-item {}
    .Nav-link {}
}
