/**
 * Button
 */

// Primary
.Button--primary {

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {}
}

// Secondary
.Button--secondary {

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {}
}

// Success
.Button--success {
    background-color: $green;
    border-color: $green;
    color: $white;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        background-color: $green--700;
        border-color: $green--700;
        color: $white;
    }
}

// Info
.Button--info {
    background-color: $blue;
    border-color: $blue;
    color: $white;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        background-color: $blue--700;
        border-color: $blue--700;
        color: $white;
    }
}

// Warning
.Button--warning {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        background-color: $orange--700;
        border-color: $orange--700;
        color: $white;
    }
}

// Danger
.Button--danger {
    background-color: $red;
    border-color: $red;
    color: $white;

    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused,
    &:active,
    &.is-active {
        background-color: $red--700;
        border-color: $red--700;
        color: $white;
    }
}

// Large
.Button--large {
    @include font-size($button-font-size * 1.25, false);
    padding-right: u($spacing-unit--lg);
    padding-left: u($spacing-unit--lg);
}

// Small
.Button--small {
    @include font-size($button-font-size * .75, false);
    padding-right: u($spacing-unit--sm);
    padding-left: u($spacing-unit--sm);
}

// Full
.Button--full {
    width: 100%;
}
