/**
 * General
 */


.Main {
    @include grid(100%, 1fr auto 1fr);
    height: 100vh;

    .Genuin {
        @include grid-area(1, 2, 2, 3);
        text-align: center;

        .Logo {
            max-width: 450px; 
        }

        .Social {
            &-link {
                img {
                    width: 40px;
                    margin: 4px;
                    height: 40px;
                }
            }
        }
    }
}